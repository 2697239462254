import React from 'react'
import { BrowserRouter, Route, useHistory } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header'
import PreLoader from './components/PreLoader'
import TopSearchArea from './components/TopSearchArea'
import Footer from './components/Footer'
import { routes } from './config'

function App() {
    const history = useHistory()

    return (
        <BrowserRouter>
            <PreLoader />
            <TopSearchArea />
            <Header />
            <ScrollToTop history={history} />
            <div>
                {routes.map(({ path, Component }) => (
                    <Route key={path} exact path={path}>
                        {({ match }) => (
                            <CSSTransition
                                in={match != null}
                                timeout={300}
                                classNames='page'
                                unmountOnExit
                            >
                                <Component />
                            </CSSTransition>
                        )}
                    </Route>
                ))}
            </div>

            <Footer />
        </BrowserRouter>
    )
}

export default App
