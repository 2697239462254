import React from 'react'

const Breadcrumb = () => {
    return (
        <div className='breadcrumb-area'>
            <div className='container'></div>
            <div className='breadcrumb-bg-curve'>
                <img src='./img/core-img/curve-5.png' alt='' />
            </div>
        </div>
    )
}

export default Breadcrumb
