import React from 'react'

const BannerCallUs = () => {
    return (
        <section className='uza-portfolio-area'>
            <div className='uza-cta-area'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-12 col-lg-6'>
                            <div className='cta-content mb-80'>
                                <h2>Заинтересованы в работе с нами?</h2>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                            <div className='cta-content mb-80 comment_area'>
                                <div className='call-now-btn'>
                                    <a href='#/'>
                                        <span>Позвоните нам сейчас:</span> +996 (312) 973
                                        906
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BannerCallUs
