import React from 'react'
import WelcomeArea from '../components/WelcomeArea'
import WelcomeContentHome from '../components/WelcomeContentHome'
import { ServiceMethodology } from './../components/ServiceMethodology'
import { NavLink } from 'react-router-dom'
import BannerCallUs from '../components/BannerCallUs'

const Home = () => {
    return (
        <div>
            <WelcomeArea>
                <WelcomeContentHome />
            </WelcomeArea>
            <section className='uza-blog-area'>
                <div className='blog-bg-curve'>
                    <img src='./img/core-img/curve-4.png' alt='' />
                </div>

                <div className='container section-padding-80-0'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='section-heading text-center'>
                                <h2>
                                    Вместе к успеху в постоянно <br />
                                    меняющемся мире!
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-lg-4'>
                            <div
                                className='single-blog-post bg-img mb-80'
                                style={{
                                    backgroundImage: `url(${'./img/20945504.jpg'})`
                                }}
                            >
                                <div className='post-content'>
                                    <a href='#/' className='post-title'>
                                        Компания RMConsult.KG
                                    </a>
                                    <p>
                                        Осуществляет деятельность и оказывает спектр
                                        услуг, содействующих развитию малого и среднего
                                        бизнеса в Кыргызской Республике.
                                    </p>
                                    <NavLink className='nav-link' to='/about'>
                                        <span className='read-more-btn'>
                                            Подробнее
                                            <i className='arrow_carrot-2right'></i>
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div
                                className='single-blog-post bg-img mb-80'
                                style={{
                                    backgroundImage: `url(${'./img/20945504.jpg'})`
                                }}
                            >
                                <div className='post-content'>
                                    <a href='#/' className='post-title'>
                                        Консалтинговые услуги компании
                                    </a>
                                    <p>
                                        Мы предоставляем комплекс услуг, направленный на
                                        решение актуальных проблем управления и
                                        экономического развития.
                                    </p>
                                    <br />

                                    <NavLink className='nav-link' to='/service'>
                                        <span className='read-more-btn'>
                                            Подробнее
                                            <i className='arrow_carrot-2right'></i>
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div
                                className='single-blog-post bg-img mb-80'
                                style={{
                                    backgroundImage: `url(${'./img/Wavy_REst-01_Single-09.jpg'})`
                                }}
                            >
                                <div className='post-content'>
                                    <a href='#/' className='post-title'>
                                        Кодекс этики
                                    </a>
                                    <p>
                                        Компания RMConsult.KG твердо привержена соблюдению
                                        этических и правовых норм во всех сферах своей
                                        бизнес-деятельности.
                                    </p>
                                    <NavLink className='nav-link' to='/сode-of-ethics'>
                                        <span className='read-more-btn'>
                                            Подробнее
                                            <i className='arrow_carrot-2right'></i>
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServiceMethodology />
            <BannerCallUs />
        </div>
    )
}
export default Home
