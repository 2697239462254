import React from 'react'
import Breadcrumb from '../components/Breadcrumb'

const CodeOfEthics = () => {
    return (
        <div>
            <Breadcrumb />
            <section className='blog-details-area section-padding-80 uza-portfolio-area'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='blog-details-content'>
                                <div className='post-details-text'>
                                    <div className='row justify-content-center'>
                                        <div className='col-12 col-lg-10'>
                                            <h2>КОДЕКС ЭТИКИ В ОТНОШЕНИЯХ С КЛИЕНТОМ</h2>
                                            <br />
                                            <p>
                                                Клиенты составляют основу бизнеса
                                                Компании, от максимального удовлетворения
                                                их потребностей зависит успех всей
                                                деятельности Компании.
                                            </p>

                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы стремимся к наивысшим стандартам
                                                    обслуживания, защищаем интересы
                                                    каждого клиента, всегда ставим
                                                    интересы клиентов на первое место.
                                                </p>
                                            </div>
                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы заинтересованы в установлении
                                                    долгосрочных партнерских
                                                    взаимоотношений с клиентами и всемерно
                                                    способствуем их развитию.
                                                </p>
                                            </div>
                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы стремимся к прозрачности во
                                                    взаимоотношениях с клиентами и
                                                    предоставляем им полную и достоверную
                                                    информации о своих услугах и
                                                    продуктах, своевременно и
                                                    конструктивно разрешаем возможные
                                                    затруднения в процессе работы.
                                                </p>
                                            </div>
                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы постоянно работаем над
                                                    совершенствованием наших продуктов и
                                                    системы обслуживания клиентов. Мы
                                                    используем собственные разработки,
                                                    передовой опыт, для того, чтобы
                                                    постоянно давать нашим клиентам
                                                    больше, чем они от нас ожидают.
                                                </p>
                                            </div>
                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы не пересматриваем работу других
                                                    консалтинговых компаний которая была
                                                    выполнена для нашего клиента ранее. Мы
                                                    не начинаем работу с новым клиентом,
                                                    если он в это же время обслуживается
                                                    другой консалтинговой компанией по
                                                    аналогичным аспектам бизнеса.
                                                </p>
                                            </div>
                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы не оказываем услуги прямым
                                                    конкурентам наших клиентов без
                                                    получения предварительного разрешения
                                                    от наших клиентов
                                                </p>
                                            </div>
                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы не беремся за проект, если знаем,
                                                    что он не принесет ценности клиенту
                                                    или за проект, который мы не в
                                                    состоянии эффективно реализовать. Мы
                                                    не беремся за проект, если
                                                    обстоятельства, находящиеся вне зоны
                                                    нашего контроля, препятствуют
                                                    успешному выполнению проекта.
                                                </p>
                                            </div>
                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы честны со своими клиентами, создаем
                                                    равные условия для всех без исключения
                                                    клиентов. Мы обеспечиваем каждого
                                                    своего клиента продуктами и услугами
                                                    самого высшего качества.
                                                </p>
                                            </div>
                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы стремимся к минимизации любых
                                                    рисков для клиента. Мы дорожим
                                                    доверием клиентов.
                                                </p>
                                            </div>
                                            <div className='comment_area  clearfix'>
                                                <p>
                                                    Мы информируем клиентов о всех наших
                                                    связях, обстоятельствах, интересах и
                                                    обязательствах, которые могут повлиять
                                                    на объективность наших услуг. В случае
                                                    возникновения конфликта интересов мы
                                                    делаем все возможное для его
                                                    разрешения максимально конструктивным
                                                    образом
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='portfolio-bg-curve'>
                    <img src='./img/core-img/curve-3.png' alt='' />
                </div>
            </section>
        </div>
    )
}

export default CodeOfEthics
