import React from 'react'
import { NavLink } from 'react-router-dom'
import { routes } from '../config'
import { useTranslation } from 'react-i18next'
import { MdLanguage } from 'react-icons/md'

const Navigation = () => {
    const { t, i18n } = useTranslation()
    const localeSaved = localStorage.getItem('i18nextLng')
    const locale = localeSaved ? localeSaved : 'ru'

    const changeLanguage = event => {
        i18n.changeLanguage(event.target.value)
        localStorage.setItem('i18nextLng', event.target.value)
    }

    return (
        <div className='classynav'>
            <ul id='nav'>
                {routes.map(route => (
                    <li key={route.path}>
                        <NavLink className='nav-link' to={route.path} exact>
                            {t(`navigation.${route.name}`)}
                        </NavLink>
                    </li>
                ))}
            </ul>
            <div className='language-container'>
                <MdLanguage />
                <select
                    className='language-selector'
                    defaultValue={locale}
                    onChange={changeLanguage}
                >
                    <option value='ru'>RU</option>
                    <option value='en'>EN</option>
                </select>
            </div>
        </div>
    )
}
export default Navigation
