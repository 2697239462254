import React from 'react'

const PreLoader = () => (
    <div id='preloader'>
        <div className='wrapper'>
            <div className='cssload-loader'></div>
        </div>
    </div>
)

export default PreLoader
