import React from 'react'
import WelcomeArea from '../components/WelcomeArea'
import WelcomeContentAbout from '../components/WelcomeContentAbout'

const About = () => (
    <div>
        <WelcomeArea>
            <WelcomeContentAbout />
        </WelcomeArea>
        <section className='uza-portfolio-single-area section-padding-80'>
            <div className='container'>
                <div className='row justify-content-between align-items-end'>
                    <div className='col-12'>
                        <div className='portfolio-details-text'>
                            <h2>История Компании</h2>
                            <p>
                                Компания реорганизована в 2019 году из ОсОО «НПФ Комтек»,
                                специализирующейся в области консалтинга и информационных
                                техологий.
                            </p>{' '}
                            <p>В нашей компетенции находятся три основные практики: </p>
                            <br />
                            <h6>
                                <i className='fa fa-check'></i>Аутсорсинг учетных функций
                                и кадровый аутсорсинг
                            </h6>
                            <h6>
                                <i className='fa fa-check'></i>Финансовый консалтинг
                            </h6>
                            <h6>
                                <i className='fa fa-check'></i>Правовой консалтинг.
                            </h6>
                            <p>
                                В команде «РМ Консалт» работают квалифицированные
                                специалисты с профессиональным опытом и стажем, что
                                позволяет обеспечивать высокие стандарты обслуживания
                                наших клиентов.
                            </p>
                            <p>
                                Компания специализируется на выполнении комплексных задач
                                по развитию малого и среднего бизнеса.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='uza-why-choose-us-area'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-content mb-80'>
                            <div className='section-heading mb-4'>
                                <h3>ФИЛОСОФИЯ КОМПАНИИ</h3>
                            </div>
                            <p>
                                Опираясь на профессиональный опыт выполнения проектов,
                                знание отраслевых особенностей, понимание внутренних
                                бизнес-процессов компаний, мы стремимся создавать решения,
                                гармонично учитывающие все аспекты, для того чтобы Клиент
                                ощущал полное взаимопонимание с Консультантом
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-thumbnail mb-80'>
                            <img className='w-100' src='img/20943613.jpg' alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='uza-why-choose-us-area'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-thumbnail mb-80'>
                            <img className='w-100' src='img/20943892.jpg' alt='' />
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-content mb-80'>
                            <div className='section-heading mb-4'>
                                <h3>НАША ЦЕЛЬ</h3>
                            </div>
                            <p>
                                Поддержка и содействие развитию частного малого и среднего
                                бизнеса в Кыргызской Республике, способствовать
                                выстраиванию бизнесов, сопоставимых с лучшими показателями
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='uza-why-choose-us-area'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-content mb-80'>
                            <div className='section-heading mb-4'>
                                <h3>МИССИЯ КОМПАНИИ</h3>
                            </div>
                            <p>
                                Быть надежным и социально ответственным партнером,
                                предоставляющим бизнесу инструменты и консалтинговые
                                услуги на высочайшем уровне, которые делают его более
                                прозрачным и управляемым, способствуя развитию и росту.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-thumbnail mb-80'>
                            <img className='w-100' src='img/20943477.jpg' alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='uza-why-choose-us-area'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-thumbnail mb-80'>
                            <img className='w-100' src='img/20943997.jpg' alt='' />
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-content mb-80'>
                            <div className='section-heading mb-4'>
                                <h3>НАШИ ЦЕННОСТИ</h3>
                            </div>
                            <ul>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Ответственность - Мы выполняем то, что обещаем, мы
                                    надежны и честны с нашими клиентами, их доверие
                                    является нашим наиболее ценным достижением
                                </li>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Конфиденциальность - Мы строго соблюдаем
                                    конфиденциальность информации, которую получаем от
                                    наших клиентов и обеспечиваем сохранность документов,
                                    получаемых и составляемых нами в ходе работ, не
                                    разглашаем их третьим лицам без согласия клиента
                                </li>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Профессионализм Мы – компетентные специалисты,
                                    ответственные по отношению к клиентам, партнерам и
                                    нашим коллегам. Профессионализм наших сотрудников –
                                    основа нашего успеха.
                                </li>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Эффективность Мы работаем так, чтобы отдача от нашей
                                    деятельности была максимальной. Мы предлагаем услуги,
                                    которые максимально полезны для наших клиентов
                                </li>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Сотрудничество Мы - команда единомышленников, которая
                                    работает для наших клиентов, придерживаясь высокого
                                    уровня деловой этики и этики консультантов и
                                    эффективного взаимодействия
                                </li>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Совершенство Мы используем все возможности для
                                    достижения высокого уровня профессионализма,
                                    мобильности и совершенствования
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='uza-why-choose-us-area'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-thumbnail mb-80'>
                            <img className='w-100' src='img/20945391.jpg' alt='' />
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='choose-us-content mb-80'>
                            <div className='section-heading mb-4'>
                                <h3>НАШИ ПРИНЦИПЫ И ОБРАЗ ДЕЙСТВИЯ</h3>
                            </div>
                            <ul>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Смотреть на бизнес глазами клиента. Мы стремимся быть
                                    ближе к клиенту, понимать и делать больше чем он
                                    ожидает
                                </li>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Способствовать решению трудных вопросов. Мы стабильно
                                    достигаем поставленных целей и выполняем свои
                                    обязательства. Мы создаем решения, которые приносят
                                    нашим клиентами результат
                                </li>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Быть партнером
                                </li>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Быть открытым и в зоне доступности перед руководством
                                    клиентов
                                </li>
                                <li>
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                    Независимость. Мы свободны от обязательств в отношении
                                    коммерческих, финансовых и государственных структур.
                                    Мы придерживаемся принципа независимости от каких-либо
                                    третьих сторон, которые могли бы повлиять на
                                    объективность наших решений
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
)
export default About
