import React from 'react'

const WelcomeArea = props => {
    return (
        <section className='welcome-area'>
            <div className='welcome-slides owl-carousel owl-loaded owl-drag'>
                <div className='single-welcome-slide'>
                    <div className='background-curve'>
                        <img src='./img/core-img/curve-1.png' alt='' />
                    </div>

                    <div className='welcome-content h-100'>
                        <div className='container h-100'>{props.children}</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WelcomeArea
