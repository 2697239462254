import React from 'react'

export const ServiceMethodology = () => {
    return (
        <section className='uza-services-area section-padding-80-0'>
            <div className='container'>
                <div className='section-heading text-center'>
                    <h3>Наша методология и общий подход к реализации проектов</h3>
                </div>
                <br />
                <div className='row'>
                    <div className='col-12 col-sm-6 col-lg-3'>
                        <div className='single-service-area mb-80'>
                            <div className='service-icon'>
                                <i className='icon_pencil-edit_alt'></i>
                            </div>
                            <h5>Этап 1</h5>
                            <p>Разработка стратегии и подробного плана работ</p>
                        </div>
                    </div>

                    <div className='col-12 col-sm-6 col-lg-3'>
                        <div className='single-service-area mb-80'>
                            <div className='service-icon'>
                                <i className='icon_genius'></i>
                            </div>
                            <h5>Этап 2</h5>
                            <p>Формирование команды экспертов</p>
                        </div>
                    </div>

                    <div className='col-12 col-sm-6 col-lg-3'>
                        <div className='single-service-area mb-80'>
                            <div className='service-icon'>
                                <i className='icon_easel'></i>
                            </div>
                            <h5>Этап 3</h5>
                            <p>Мониторинг работ</p>
                        </div>
                    </div>

                    <div className='col-12 col-sm-6 col-lg-3'>
                        <div className='single-service-area mb-80'>
                            <div className='service-icon'>
                                <i className='icon_lightbulb_alt'></i>
                            </div>
                            <h5>Этап 4</h5>
                            <p>Рекомендации для дальнейшего развития</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
