import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import BannerCallUs from './../components/BannerCallUs'
import ScoreBanner from './../components/ScoreBanner'

const Partners = () => {
    return (
        <div>
            <Breadcrumb />
            <section class='uza-about-us-area'>
                <div class='container'>
                    <h1 class='mb-40'>Наши партнеры</h1>
                    <div class='row'>
                        <div class='col-12 col-lg-4'>
                            <img src='./img/bg-img/2cc.jpg' alt='' />
                        </div>

                        <div class='col-12 col-lg-8'>
                            <div class='section-heading mb-5'>
                                <h3>Салтанат Тажибаева</h3>
                                <h5>директор в ОсОО «РМ Консалт»</h5>
                            </div>

                            <div class='about-us-content mb-80'>
                                <p>
                                    Салтанат проработала в сфере аудита и консалтинга
                                    более 23 лет, начав карьеру с должности бухгалтера в
                                    компании «HLBМарка Аудит» в Кыргызской Республике,
                                    прошла путь специалиста, консультанта, финансового
                                    директора, директора и партнера в этой же компании.
                                </p>

                                <p>
                                    Является сертифицированным аудитором Кыргызской
                                    Республики.Имеет специалитет экономических наук
                                    Кыргызского Государственного Национального
                                    Университета.
                                </p>
                                <p>
                                    Является членом Совета директоров Объединения
                                    Бухгалтеров и Аудиторов Кыргызской Республики, Комитет
                                    по стратегическому развитию, член Совета директоров
                                    ОсОО «Микрокредитная Компания «Универсал Кредит»,
                                    полноправный член ОО «Палата Независимых Директоров
                                    Кыргызской Республики».
                                </p>

                                <p>
                                    Во время своей деятельности работала в рамках проектов
                                    по аудиту и трансформации, проводимых на условиях
                                    подряда с такими крупными международными компаниями
                                    как ПрайсУотерхаус (Казахстан), KPMGJannat
                                    (Казахстан). В качестве директора компании HLBМарка
                                    Аудит занималась стратегией и развитием компании,
                                    руководила крупными проектами как крупных предприятий
                                    государственного и частного секторов, так и в рамках
                                    проектов международных финансовой институтов, таких
                                    как ЕБРР, АБР, ИБР и других международных доноров.
                                    Являлась партнером по развитию международной сети
                                    HLBInternationalв Кыргызстане. В течении 2006-2013
                                    годов также представляла интересы компании “HLBМарка
                                    Аудит» в Казахстане в дочерней компании ТОО «Марка
                                    Аудит Казахстан», работая совместно над общими
                                    проектами в сфере консалтинга и аудита.
                                </p>
                                <p>
                                    Салтанат воспитывает сына, интересуется лыжами, любит
                                    читать книги и путшествовать. Связаться с Салтанат
                                    можно по электронной почте stazhibaeva@rmconsult.kg.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='col-12 col-lg-4'>
                            <img src='./img/bg-img/2cc.jpg' alt='' />
                        </div>

                        <div class='col-12 col-lg-8'>
                            <div class='section-heading mb-5'>
                                <h3>Светлана Шиян</h3>
                                <h5>партнер в ОсОО «РМ Консалт»</h5>
                            </div>

                            <div class='about-us-content'>
                                <p>
                                    Светлана проработала в сфере права и консалтинга более
                                    23 лет, начав карьеру с должности юриста в компании с
                                    иностранными инвестициями в Кыргызской Республике,
                                    прошла путь специалиста, начальника отдела по работе с
                                    дебиторами в государственной акционерной компании,
                                    заместителя директора в аудиторской компании, партнера
                                    и собственника в «РМ Консалт». Имеет два высших
                                    образования.
                                </p>

                                <p>
                                    Является дипломированным юристом, окончив юридический
                                    факультет Кыргызско-Российского Славянского
                                    Университета. Во время своей деятельности работала по
                                    проектам, проводимым в рамках программ
                                    консультационной поддержки и развития Европейского
                                    Банка Реконструкции и Развития, а также финансируемых
                                    Азиатским Банком Развития и другими международными
                                    учреждениями.
                                </p>

                                <p>
                                    В качестве юриста занималась вопросами консультаций,
                                    анализа и подготовки нормативных правовых актов,
                                    инвестиционных соглашений, принимала участие в
                                    судебных разбирательствах по вопросам погашения
                                    дебиторской и кредиторской задолженности, оказывала
                                    консультационную поддержку и правовой анализ для
                                    крупных предприятий государственного и частного
                                    секторов во всех сферах экономической деятельности.
                                    Имеет опыт работы с законодательством Республики
                                    Казахстан и Российской Федерации.
                                </p>

                                <p>
                                    Светлана замужем, воспитывает трех детей, любит читать
                                    книги и путшествовать. Связаться с Светланой можно по
                                    электронной почте sshiyan@rmconsult.kg.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='about-bg-pattern'>
                    <img src='./img/core-img/curve-2.png' alt='' />
                </div>
            </section>
            <div class='container'>
                <div class='border-line mt-80'></div>
            </div>
            <ScoreBanner />
            <BannerCallUs />
        </div>
    )
}

export default Partners
