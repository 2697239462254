import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import { ServiceMethodology } from '../components/ServiceMethodology'

const Services = () => (
    <div>
        <Breadcrumb />
        <section className='uza-why-choose-us-area'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-12 col-lg-5'>
                        <div className='about-us-thumbnail mb-80'>
                            <img src='./img/Wavy_REst-01_Single-09.jpg' alt='' />
                        </div>
                    </div>

                    <div className='col-12 col-lg-7'>
                        <div className='section-heading mb-5'>
                            <h3>Консалтинговые продукты компании</h3>
                        </div>

                        <div className='about-us-content mb-80'>
                            <div className='about-tab-area'>
                                <ul
                                    className='nav nav-tabs mb-50'
                                    id='mona_modelTab'
                                    role='tablist'
                                >
                                    <li className='nav-item'>
                                        <a
                                            className='nav-link active'
                                            id='tab1'
                                            data-toggle='tab'
                                            href='#tab-1'
                                            role='tab'
                                            aria-controls='tab-1'
                                            aria-selected='true'
                                        >
                                            КОНСАЛТИНГ
                                        </a>
                                    </li>
                                    <li className='nav-item'>
                                        <a
                                            className='nav-link'
                                            id='tab2'
                                            data-toggle='tab'
                                            href='#tab-2'
                                            role='tab'
                                            aria-controls='tab-2'
                                            aria-selected='false'
                                        >
                                            АУТСОРСИНГ
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className='about-tab-content'>
                                <div className='tab-content' id='mona_modelTabContent'>
                                    <div
                                        className='tab-pane fade show active'
                                        id='tab-1'
                                        role='tabpanel'
                                        aria-labelledby='tab1'
                                    >
                                        <div className='tab-content-text'>
                                            <h5>Финансовый консалтинг</h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Подготовка и консалтинг по
                                                        внедрению корпоративного
                                                        управления в компании
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Внутренний контроль
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Консультации по бухучету, налогам,
                                                        управленческому учету
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Сопровождение для целей получения
                                                        финансирование
                                                    </p>
                                                </li>
                                            </ul>

                                            <h5>Юридические услуги</h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Правовые консультации
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Регистрацию юридических лиц в
                                                        Кыргызстане
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Визовая поддержка, получение
                                                        разрешений на работу в КР
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Функция управляющей компании
                                                    </p>
                                                </li>
                                            </ul>

                                            <h5>Услуга профессионального работодателя</h5>
                                            <h5>
                                                Forensicанализ – анализ закупок работ,
                                                услуг и выполнения
                                            </h5>
                                            <h5>
                                                Due Dilligence – финансовый и правовой
                                            </h5>
                                        </div>
                                    </div>

                                    <div
                                        className='tab-pane fade'
                                        id='tab-2'
                                        role='tabpanel'
                                        aria-labelledby='tab2'
                                    >
                                        <div className='tab-content-text'>
                                            <h5>Бухгалтерский учет</h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Комплексное ведение учета
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Ведение отдельных участков учета
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Проверка и восстановление учета
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Постановка финансового и
                                                        налогового учета
                                                    </p>
                                                </li>
                                            </ul>

                                            <h5>Расчет заработной платы</h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Аутсорсинг расчета заработной
                                                        платы
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Расчет заработной платы ключевых
                                                        сотрдуников
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Поддержка функции расчета
                                                        заработной платы
                                                    </p>
                                                </li>
                                            </ul>

                                            <h5>Кадровый аутсорсинг</h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Ведение кадрового учета
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Восстановление кадровой
                                                        документации
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className='fa fa-check'></i>
                                                        Кадровый аудит
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <ServiceMethodology />
    </div>
)

export default Services
