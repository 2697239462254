import React from 'react'
import Breadcrumb from '../components/Breadcrumb'

const ContactUs = () => {
    return (
        <div>
            <Breadcrumb />
            <section className='uza-contact-area section-padding-80-0'>
                <div className='container'>
                    <div className='contact-heading mb-50'>
                        <h4>
                            Спасибо за то, что вы посетили сайт «RMConsult.KG». <br />
                            Мы будем рады сотрудничеству с вами!
                            <br />
                            Если у вас возникли вопросы, обращайтесь к нам.
                        </h4>
                    </div>
                    <div className='row justify-content-between'>
                        <div className='col-12 col-lg-8'>
                            <div className='uza-contact-form mb-80'>
                                <div className='google-maps'>
                                    <iframe
                                        src=' https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2924.3774507355633!2d74.58891681547236!3d42.86487677915619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389ec829bb19eb4d%3A0x1b58842c5bf05245!2zMiwgMSDRg9C7LiDQmNGB0LDQvdC-0LLQsCwg0JHQuNGI0LrQtdC6LCDQmtC40YDQs9C40LfQuNGP!5e0!3m2!1sru!2sfr!4v1623662014413!5m2!1sru!2sfr'
                                        width={800}
                                        height={600}
                                        style={{ border: 0 }}
                                        allowFullScreen
                                        loading='lazy'
                                        title='Map'
                                    ></iframe>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-4'>
                            <div className='contact-sidebar-area mb-80'>
                                <div className='single-contact-card mb-50'>
                                    <h5>
                                        Консалтинговая компания <br />
                                        «Ратио Мастер Консалт»
                                    </h5>
                                    <h6>
                                        720017 Кыргызская Республика <br />
                                        г. Бишкек, ул. Исанова 1, офис 2
                                    </h6>
                                </div>

                                <div className='single-contact-card mb-50'>
                                    <h3>+996 (312) 973 906</h3>
                                    <h6>Email: office@rmconsult.kg</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ContactUs
