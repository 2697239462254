import React from 'react'
import Navigation from './Navigation'

const Header = () => (
    <header className='header-area'>
        <div className='main-header-area'>
            <div className='classy-nav-container breakpoint-off'>
                <nav className='classy-navbar justify-content-between' id='uzaNav'>
                    <a className='nav-brand' href='#/'>
                        <img src='./img/core-img/logo.png' alt='' />
                    </a>

                    <div className='classy-navbar-toggler'>
                        <span className='navbarToggler'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </div>

                    <div className='classy-menu'>
                        <div className='classycloseIcon'>
                            <div className='cross-wrap'>
                                <span className='top'></span>
                                <span className='bottom'></span>
                            </div>
                        </div>

                        <Navigation />
                    </div>
                </nav>
            </div>
        </div>
    </header>
)

export default Header
