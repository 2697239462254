import React from 'react'

const ScoreBanner = () => {
    return (
        <section>
            <div class='uza-cf-area section-padding-80-0'>
                <div class='container'>
                    <div class='row'>
                        <div class='col-12 col-sm-6 col-lg-4'>
                            <div class='single-cf-area d-flex align-items-center mb-80'>
                                <h2>
                                    <span class='counter'>90</span>
                                </h2>
                                <div class='cf-text'>
                                    <h6>
                                        Завершенных
                                        <br />
                                        проектов
                                    </h6>
                                </div>
                                <div class='bg-icon'>
                                    <i class='icon_piechart'></i>
                                </div>
                            </div>
                        </div>

                        <div class='col-12 col-sm-6 col-lg-4'>
                            <div class='single-cf-area d-flex align-items-center mb-80'>
                                <h2>
                                    <span class='counter'>120</span>
                                </h2>
                                <div class='cf-text'>
                                    <h6>
                                        Довольных
                                        <br />
                                        клиентов
                                    </h6>
                                </div>
                                <div class='bg-icon'>
                                    <i class='icon_heart_alt'></i>
                                </div>
                            </div>
                        </div>

                        <div class='col-12 col-sm-6 col-lg-4'>
                            <div class='single-cf-area d-flex align-items-center mb-80'>
                                <h2>
                                    <span class='counter'>20</span>
                                </h2>
                                <div class='cf-text'>
                                    <h6>
                                        Наших
                                        <br />
                                        специалистов
                                    </h6>
                                </div>
                                <div class='bg-icon'>
                                    <i class='icon_profile'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ScoreBanner
