import React from 'react'

const WelcomeContentAbout = () => {
    return (
        <div className='row padding-top-200 align-items-center'>
            <div className='col-12 col-md-6'>
                <div className='welcome-text'>
                    <h2 data-animation='fadeInUp' data-delay='100ms'>
                        Консалтинговая компания
                        <br />
                        <span>«Ратио Мастер Консалт»</span>
                    </h2>
                    <h5 data-animation='fadeInUp' data-delay='400ms'>
                        Осуществляет деятельность и оказывает спектр услуг, содействующих
                        развитию малого и среднего бизнеса в Кыргызской Республике.
                    </h5>
                </div>
            </div>

            <div className='col-12 col-md-6'>
                <div className='welcome-thumbnail'>
                    <img
                        src='./img/bg-img/118134_p_20.png'
                        data-animation='slideInRight'
                        data-delay='400ms'
                        alt=''
                    />
                </div>
            </div>
        </div>
    )
}

export default WelcomeContentAbout
