import Home from './../pages/Home'
import About from './../pages/About'
import Services from './../pages/Services'
import ContactUs from './../pages/ContactUs'
import CodeOfEthics from './../pages/CodeOfEthics'
import Partners from './../pages/Partners'

const routes = [
    { path: '/', name: 'home', Component: Home },
    { path: '/about', name: 'about', Component: About },
    { path: '/service', name: 'service', Component: Services },
    { path: '/partners', name: 'partners', Component: Partners },
    { path: '/сode-of-ethics', name: 'сodeOfEthics', Component: CodeOfEthics },
    { path: '/contact-us', name: 'contactUs', Component: ContactUs }
]

export default routes
