import React from 'react'

const Footer = () => (
    <footer className='footer-area section-padding-80-0'>
        <div className='comment_area'>
            <div className='container'>
                <div className='row justify-content-between'>
                    <div className='col-12 col-sm-6 col-lg-4'>
                        <div className='single-footer-widget mb-80'>
                            <h4 className='widget-title'>Связаться с нами</h4>

                            <div className='footer-content mb-15'>
                                <h3>+996 (312) 973 906</h3>
                                <p>
                                    <b>Юридический адрес</b>: 720031 Кыргызская
                                    Республика, г. Бишкек, ул. Кулатова 1а
                                    <br />
                                    <b>Офис</b>:720017 Кыргызская Республика г. Бишкек,
                                    ул. Исанова 1, офис 2
                                    <br />
                                    office@rmconsult.kg
                                </p>
                                {/*   <div className='footer-social-info'>
                                    <a
                                        href='#/'
                                        className='facebook'
                                        data-toggle='tooltip'
                                        data-placement='top'
                                        title='Facebook'
                                    >
                                        <i className='fa fa-facebook'></i>
                                    </a>
                                    <a
                                        href='#/'
                                        className='twitter'
                                        data-toggle='tooltip'
                                        data-placement='top'
                                        title='Twitter'
                                    >
                                        <i className='fa fa-twitter'></i>
                                    </a>
                                    <a
                                        href='#/'
                                        className='pinterest'
                                        data-toggle='tooltip'
                                        data-placement='top'
                                        title='Pinterest'
                                    >
                                        <i className='fa fa-pinterest'></i>
                                    </a>
                                    <a
                                        href='#/'
                                        className='instagram'
                                        data-toggle='tooltip'
                                        data-placement='top'
                                        title='Instagram'
                                    >
                                        <i className='fa fa-instagram'></i>
                                    </a>
                                    <a
                                        href='#/'
                                        className='youtube'
                                        data-toggle='tooltip'
                                        data-placement='top'
                                        title='YouTube'
                                    >
                                        <i className='fa fa-youtube-play'></i>
                                    </a>
                                </div>
*/}
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-sm-6 col-lg-4'>
                        <div className='single-footer-widget mb-80'>
                            <h4 className='widget-title'>Прямые ссылки</h4>
                            <nav>
                                <ul className='our-link'>
                                    <li>
                                        <a href='/'>Главная</a>
                                    </li>
                                    <li>
                                        <a href='/about'>О нас</a>
                                    </li>
                                    <li>
                                        <a href='/service'>Услуги</a>
                                    </li>
                                    <li>
                                        <a href='/сode-of-ethics'>Кодекс этики</a>
                                    </li>
                                    <li>
                                        <a href='/contact-us'>Связаться с нами</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div className='col-12 col-sm-6 col-lg-4'>
                        <div className='single-footer-widget mb-80'>
                            <h4 className='widget-title'>О нас</h4>
                            <p>
                                RMConsultKG
                                <br />
                                Услуги по консалтингу
                                <br />
                                Финансовые консультации. <br />
                                Аутсорсинг. Ведение бухгалтерии.
                            </p>

                            <div className='copywrite-text mb-30'>
                                <p>
                                    &copy; Copyright 2021{' - '}
                                    <a href='http://rmconsult.kg/'>RMConsultKG</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
